import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Checkbox/Checkbox.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import WebComponent from '../../../WebComponent.js'
import '../../../components/Betrieb/BetriebSearchDialog.js'
import '../../../components/StatusIcon/StatusIcon.js'
import '../../../components/Betrieb/BetriebDiffDialog.js'
import I18n from '../../../i18n.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import GesuchService from '../../../services/GesuchService.js'
import ExternalBetriebSearchService from '../../../services/ExternalBetriebSearchService'
import StammdatenService from '../../../services/StammdatenService.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import DataHelper from '../Common/DataHelper.js';
import AdressverzeichnisService from '../../../services/AdressverzeichnisService'
import '../../../components/SearchableTextInput/SearchableTextInput.js'
import '../../../components/PlzOrtInput/PlzOrtInput.js'
import VzavgPattern from '../../../common/VzavgPattern.js'
import '../../../components/ErrorMsg/ErrorMsg.js'
import Options from '../../../common/Options.js'
import '../../../components/UidSearchButton/UidSearchButton.js'
import {geschaeftsVorfallNavigationLinks, geschaeftsVorfallNavigationLinksHelper} from '../Geschaeftsvorfall.js'
import '../../../components/Title/Title.js'
import BetriebCss from './BetriebCss'

export const betriebStatuses = i18n => {
  return [
    {
      value: 'active',
      name: i18n.translate('geschaeftsvorfall.betrieb.status.active')
    },
    {
      value: 'inactive',
      name: i18n.translate('geschaeftsvorfall.betrieb.status.inactive')
    }
  ]
}

export default class Betrieb extends WebComponent {

  static BETRIEB_FORM_ID = 'betrieb-form'
  static BETRIEB_SEARCH_DIALOG_ID = 'betrieb-search-dialog'
  static BETRIEB_UID_ADD_BUTTON = 'add_uid_button'
  static BETRIEB_UID_BUTTON_WRAPPER = 'uid_button-wrapper'
  static BEWILLIGGUNGSPFLICHT_FIELD_ID = 'bewilligungspflicht'
  static FIRMEENBEZ_FIELD_ID = 'firmenbezeichnung'
  static STRASSE_FIELD_ID = 'strasse'
  static PLZ_ORT_FIELD_ID = 'plzOrt'
  static ORT_FIELD_ID = 'ort'
  static TELEFON_FIELD_ID = 'telefon'
  static EMAIL_FIELD_ID = 'email'
  static SCHWEIZ = 'Schweiz'
  static LIECHTENSTEIN = 'Liechtenstein'
  static TELEFON_DEFAULT = '+41 '
  static TELEFON_DEFAULT_LI = '+423 '
  static LINK_FIELD_ID = 'dmsLink'

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.stammdatenService = new StammdatenService()
    this.adressverzeichnisService = new AdressverzeichnisService()
    this.service = new GesuchService()
    this.searchService = new ExternalBetriebSearchService()
    this.otherBetriebeWithSameUid = []
    this.kantone = []
    this.leander = []
  }

  get css() {
    return [BetriebCss]
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Betrieb/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    if (!isNewGesuch()) {
      this._updateBetrieb()
    }

    this._refresh = () => this._getDataAndRender(window.betrieb$.getValue())

    this.betriebSubscription = window.betrieb$.subscribe(value => {
      this.hasNoBetrieb = value === null
      this._getDataAndRender(value)
    })

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    this.betriebSubscription.unsubscribe();
  }

  _fetchChecklisten() {
    if (isNewGesuch()) {
      return Promise.resolve(true)
    }
    return this.service.readBetriebChecklist(this.gesuchId)
      .then(res1 => {
        const checklisten = {kantonCheckList: [], secoCheckList: []}
        if (res1.checks) {
          for (const check of res1.checks) {
            if (check.bereich === 'kanton') {
              checklisten.kantonCheckList.push(check)
            } else {
              checklisten.secoCheckList.push(check)
            }
          }
        }
        return checklisten
      }).catch(_ => [])
  }

  _fetchLaender() {
    const countryList = this.adressverzeichnisService.countries
      .map(country => ({value: country, name: country}))
      .sort((a, b) => a.name - b.name)

    const schweiz = countryList.splice(countryList.findIndex(country => country.value === Betrieb.SCHWEIZ), 1)
    const liechtenstein = countryList.splice(countryList.findIndex(country => country.value === Betrieb.LIECHTENSTEIN), 1)

    return [...schweiz, ...liechtenstein, {
      name: '--------------------',
      value: 'separator',
      disabled: true
    }, ...countryList]
  }

  _fetchKantone() {
    return this.stammdatenService.getOrganisation().then(kantone => {
      const kantoneList = []
      for (const kanton of kantone) {
        kantoneList.push({value: kanton.code, name: kanton.bezeichnung === undefined ? '' : kanton.bezeichnung})
      }
      return kantoneList
    }).catch(_ => {
      showAlert('Stammdaten-Service Kantone unavailable', 'Request to backend could not be sent', 'error')
      return []
    })
  }

  _fetchOberbetriebWithSameUid(betrieb) {
    if (betrieb.unternRegisterId) {
      return this.searchService.searchLocalByUid(betrieb.unternRegisterId)
        .then(res => res.filter(b => b.id !== betrieb.id))
        .catch(() => [])
    }
    return Promise.resolve([])
  }

  _getDataAndRender(betrieb) {
    if (!betrieb) {
      betrieb = this._createDefaultBackendData()
    }

    this.backendData = betrieb
    this.isShowingNotizErfassen = this.backendData.zaVerbot === 'yes'
    this.backendData.gemeinnuetzigkeit = this.backendData.gemeinnuetzigkeit ? this.backendData.gemeinnuetzigkeit : 'no'
    this.backendData.rgEmpfang = this.backendData.rgEmpfang ? this.backendData.rgEmpfang : 'paper'
    this._configureTelefonField()

    const otherBetriebe$ = this._fetchOberbetriebWithSameUid(betrieb).then(otherBetriebeWithSameUid => {
      this.otherBetriebeWithSameUid = otherBetriebeWithSameUid
    })
    const checklists$ = this._fetchChecklisten().then(checklists => {
      this.backendData.kantonCheckList = checklists.kantonCheckList
      this.backendData.secoCheckList = checklists.secoCheckList
    })
    this.backendData.isChecklistOverridden = AuthorizationFilter.hasKantonRole() ? this.backendData.isKantonChecklistOverridden : this.backendData.isSecoChecklistOverridden

    Promise.all([otherBetriebe$, checklists$]).then(_ => this.render())

    // load laender/kantone, can be done after rendering betrieb because we have a LitElement for laender/kantone
    // the refresh mechanism is automatic over property changes. Otherwise, it can take to long time to render the page
    return Promise.all([this._fetchKantone(), this._fetchLaender()])
      .then(([kantone, laender]) => {
        this.leander = laender
        this.kantone = kantone
        this.render()
      })
  }

  _openDokumenteViewer() {
    window.open(constructURL({
      to: `#${geschaeftsVorfallNavigationLinks.documentViewer.hash}`,
      id: this.gesuchId
    }), "_blank");
  }

  getTemplate() {
    return html`
      <vzavg-title text="${this.i18n.translate('geschaeftsvorfall')}">
        <div slot="center" class="title-content">
          <vzavg-checklist
            id="kantonCheckListChecks"
            name="kantonCheckListChecks"
            type="kanton"
            .items="${this.backendData.kantonCheckList}"
            .isChecklistOverridden="${this.backendData.isKantonChecklistOverridden}"
            .confirmCallback="${_ => this._handleKantonCheckList()}"
          ></vzavg-checklist>
          <isceco-button
            id="buttonDokumenteAnzeigen"
            icon="file pdf outline"
            variant="secondary"
            @click="${_ => this._openDokumenteViewer()}"
          ></isceco-button>
          <vzavg-checklist
            id="secoCheckListChecks"
            name="secoCheckListChecks"
            type="seco"
            .items="${this.backendData.secoCheckList}"
            .isChecklistOverridden="${this.backendData.isSecoChecklistOverridden}"
            .confirmCallback="${_ => this._handleSecoCheckList()}"
          ></vzavg-checklist>
        </div>
        <div slot="right" class="title-content">
          ${this._renderExistingBetriebe()}

          <isceco-button
            id="save"
            title="${this.i18n.translate('geschaeftsvorfall.betrieb.button.speichern')}"
            icon="save"
            variant="primary"
            @click="${_ => this.save()}"
            ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
          ></isceco-button>
          <vzavg-checklist-ok
            id="checklistOk"
            name="checklistOk"
            .disabled="${this.backendData.isChecklistOverridden}"
            .hidden="${!this._isBewilligungsPflichtig()}"
            .clickCallback="${_ => this._handleChecklistOverride()}"
          ></vzavg-checklist-ok>
          <isceco-button
            id="buttonWeiter"
            variant="primary"
            icon="chevron right"
            ?disabled="${this.hasNoBetrieb}"
            @click="${_ => this._handleClickNext()}"
          ></isceco-button>
        </div>
      </vzavg-title>
      <isceco-form
        id="${Betrieb.BETRIEB_FORM_ID}"
        @submit="${() => this.save()}">
        <div slot="form-elements">
          <div class="flex">
            ${this._renderColumn1()}
            ${this._renderColumn2()}
            ${this._renderColumn3()}
          </div>
        </div>
      </isceco-form>

      ${this._renderDialogs()}
    `
  }

  _renderColumn1() {
    return html`
      <div class="flex-column">
        <isceco-dropdown
          ?readonly="${AuthorizationFilter.hasKantonRole() ||
          AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco) ||
          this.backendData.codeKanton !== 'SECO' && !AuthorizationFilter.hasSecoRole()}"
          id="kanton"
          name="kanton"
          label="${this.responsibilityLabel}"
          value="${this.backendData.codeKanton}"
          .items="${this.kantone}"
          @change="${e => this._updateKantonCode(e)}"
          ?required="${this.kantonIsRequired}">
        </isceco-dropdown>
        <vzavg-searchable-text-input
          id="land"
          name="land"
          .items="${this.backendData.codeKanton !== 'SG' ? this.leander : this.leander.slice(0, 2)}"
          value="${this.backendData.land}"
          maxLength="255"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.land')} *"
          required
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco) ||
          (this.backendData.codeKanton !== 'SECO' && this.backendData.codeKanton !== 'SG' && !AuthorizationFilter.hasSecoRole())}"
          @change="${e => this._handleChangeLand(e)}">
        </vzavg-searchable-text-input>
        ${this._renderMandatoryInfo()}
        ${this._isAuslandUndSeco() ? html`` : html`
          <isceco-radio-group
            id="gemeinnuetzigkeit"
            name="gemeinnuetzigkeit"
            value="${this.backendData.gemeinnuetzigkeit}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.gemeinnuetzigkeit')}"
            .items="${Options.yesNo}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            @change="${e => this._updateValueAndReload(e)}"
            ?required="${this.backendData.bewilligungspflicht === 'yes'}">
          </isceco-radio-group>
        `}
        ${this._isNotInAbklaerung() ? html`
          <isceco-radio-group
            id="berufsverband"
            name="berufsverband"
            value="${this.backendData.berufsverband}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.berufsverband')}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            .items="${Options.yesNo}"
            @change="${e => this._updateValueAndReload(e)}">
          </isceco-radio-group>
        ` : html``}
        ${this._renderZaVerbot()}
      </div>
    `
  }

  _renderColumn2() {
    return html`
      <div class="flex-column">
        ${this._renderUIDButton()}
        <isceco-text-input
          id="${Betrieb.FIRMEENBEZ_FIELD_ID}"
          name="${Betrieb.FIRMEENBEZ_FIELD_ID}"
          value="${this.backendData.firmenbezeichnung}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.firmenbezeichnung')} *"
          maxLength="100"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          required
        ></isceco-text-input>
        <isceco-text-input
          id="adresszusatz"
          name="adresszusatz"
          value="${this.backendData.adresszusatz}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.adresszusatz')}"
          maxLength="255"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        ></isceco-text-input>
        <isceco-text-input
          id="${Betrieb.STRASSE_FIELD_ID}"
          name="${Betrieb.STRASSE_FIELD_ID}"
          value="${this.backendData.strasse}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.strasse') + (this._isAdressFieldRequired() ? ' *' : '')}"
          maxLength="100"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ?required="${this._isAdressFieldRequired()}"
        ></isceco-text-input>

        <div class="flex">
          ${this._isLandSchweizOrLichtenstein() ? html`
            <vzavg-plz-ort-input
              id="${Betrieb.PLZ_ORT_FIELD_ID}"
              name="${Betrieb.PLZ_ORT_FIELD_ID}"
              .i18n="${this.i18n}"
              .ortValue="${this.backendData.ort}"
              .plzValue="${this.backendData.plz}"
              ?required="${this._isBewilligungsPflichtig()}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              @change="${e => this._updatePlzOrtValue(e)}"
            ></vzavg-plz-ort-input>
          ` : html`
            <isceco-text-input
              id="${Betrieb.ORT_FIELD_ID}"
              name="${Betrieb.ORT_FIELD_ID}"
              value="${this.backendData.ort}"
              label="${this.plzOrtAuslandLabel}"
              maxLength="255"
              style="flex:1; min-width: 6em"
              ?required="${this._isBewilligungsPflichtig()}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            ></isceco-text-input>
          `}
        </div>
        <isceco-text-input
          id="${Betrieb.TELEFON_FIELD_ID}"
          name="${Betrieb.TELEFON_FIELD_ID}"
          value="${this.backendData.telefon}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.telefon') + (this._isTelOrEmailRequired() ? ' *' : '')}"
          ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
          @change="${e => this._updateValue(e)}"
          pattern="${this.telefonPattern}"
          pattern-error-text="${this.telefonPatternErrorMsg}"
          placeholder="${this.telefonPlaceholder}"
          ?required="${this._isTelOrEmailRequired()}"
        ></isceco-text-input>
      </div>
    `
  }

  _renderColumn3() {
    return html`
      <div class="flex-column">
        ${this._renderEmail()}
        ${this._renderWeb()}
        ${this._renderMarken()}
        ${this._renderPortalUser()}
        ${this._renderRgEmpfang()}
        ${this._renderAnzahlZweigniederlassungen()}
        ${this._renderKorrespondenzSprache()}
        ${this._renderDmsLink()}
      </div>
    `
  }

  _renderEmail() {
    return html`
      <isceco-text-input
        id="${Betrieb.EMAIL_FIELD_ID}"
        name="${Betrieb.EMAIL_FIELD_ID}"
        value="${this.backendData.email}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.email') + (this._isTelOrEmailRequired() && this._isLandSchweizOrLichtenstein() ? ' *' : '')}"
        @change="${e => this._updateValue(e)}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
        pattern="${VzavgPattern.emailPattern}"
        maxLength="255"
        pattern-error-text="${this.i18n.translate('geschaeftsvorfall.betrieb.email.pattern.error')}"
        ?required="${this._isTelOrEmailRequired() && this._isLandSchweizOrLichtenstein()}"
      ></isceco-text-input>
    `
  }

  _renderWeb() {
    return html`
      <isceco-text-input
        id="web"
        name="web"
        value="${this.backendData.web}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.web')}"
        placeholder="www."
        maxLength="255"
        @change="${e => this._updateValue(e)}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
        pattern="${VzavgPattern.urlPattern}"
        pattern-error-text="${this.i18n.translate('geschaeftsvorfall.betrieb.web.pattern.error')}"
      ></isceco-text-input>
    `
  }

  _renderZaVerbot() {
    if (!this._isSchweizAlleKantoneNichtInAbklaehrung()) {
      return html``
    }

    return html`
      <isceco-radio-group
        id="zaVerbot"
        name="zaVerbot"
        value="${this.backendData.zaVerbot}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.zaVerbot')}"
        .items="${Options.yesNo}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
        @change="${e => this._updateZAVerbot(e)}"
      ></isceco-radio-group>
      ${this.isShowingNotizErfassen ? html`
        <isceco-button
          id="notizErfassen"
          variant="secondary"
          text="${this.i18n.translate('geschaeftsvorfall.notizErfassen')}"
          @click="${_ => this._openNewNotiz()}"
        ></isceco-button>
      ` : html``}
    `
  }

  _renderMarken() {
    if (!this._isSchweizAlleKantoneNichtInAbklaehrung()) {
      return html``
    }

    return html`
      <isceco-text-input
        id="marken"
        name="marken"
        maxLength="300"
        value="${this.backendData.marken}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.marken')}"
        @change="${e => this._updateValue(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}">
      </isceco-text-input>
    `
  }

  _renderPortalUser() {
    if (!(this._isSchweizAlleKantoneNichtInAbklaehrung() || this._isLiechtensteinUndStGallen())) {
      return html``
    }

    return html`
      <isceco-dropdown
        id="portalUser"
        name="portalUser"
        value="${this.backendData.portalUser}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.portal.user')} *"
        .items="${[
          {value: 'yes', name: this.i18n.translate('geschaeftsvorfall.betrieb.portal.user.yes')},
          {value: 'no', name: this.i18n.translate('geschaeftsvorfall.betrieb.portal.user.no')}
        ]}"
        @change="${e => this._updateValue(e)}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
        required>
      </isceco-dropdown>
    `
  }

  _renderRgEmpfang() {
    if (!(this._isSchweizAlleKantoneNichtInAbklaehrung() || this._isLiechtensteinUndStGallen())) {
      return html``
    }

    return html`
      <isceco-dropdown
        id="rgEmpfang"
        name="rgEmpfang"
        value="${this.backendData.rgEmpfang}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.rg.empfang')}"
        .items="${[
          {value: 'electronic', name: this.i18n.translate('geschaeftsvorfall.betrieb.rg.empfang.electronic')},
          {value: 'paper', name: this.i18n.translate('geschaeftsvorfall.betrieb.rg.empfang.papier')}
        ]}"
        @change="${e => this._updateValue(e)}"
        placeholder="${this.i18n.translate('geschaeftsvorfall.placeholder.bitte.auswaehlen')}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess() || !AuthorizationFilter.hasSecoRole()}">
      </isceco-dropdown>
    `
  }

  _renderAnzahlZweigniederlassungen() {
    if (!this._isSchweizAlleKantoneNichtInAbklaehrung()) {
      return html``
    }

    return html`
      <isceco-text-input
        id="anzahlZweigniederlassungen"
        name="anzahlZweigniederlassungen"
        value="${this.backendData.anzahlZweigniederlassungen}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.anzahl.zweigniederlassungen')}"
        @change="${e => this._updateValue(e)}"
        pattern="[0-9]*"
        maxLength="3"
        pattern-error-text="${this.i18n.translate('geschaeftsvorfall.betrieb.anzahl.zweigniederlassung.pattern.error')}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}">
      </isceco-text-input>
    `
  }

  _renderKorrespondenzSprache() {
    return html`
      <isceco-dropdown
        id="korrespondenzSprache"
        name="korrespondenzSprache"
        value="${this.backendData.korrespondenzSprache}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.korr.sprache')} *"
        .items="${this._isAuslandUndSeco() ? [
            {value: 'de', name: 'de'},
            {value: 'fr', name: 'fr'},
            {value: 'it', name: 'it'},
            {value: 'en', name: 'en'}]
          : [
            {value: 'de', name: 'de'},
            {value: 'fr', name: 'fr'},
            {value: 'it', name: 'it'}
          ]}"
        @change="${e => this._updateValue(e)}"
        ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
        required>
      </isceco-dropdown>
    `
  }

  _renderDmsLink() {
    return AuthorizationFilter.hasSecoRole() ? html`
      <isceco-text-input
          id="${Betrieb.LINK_FIELD_ID}"
          name="${Betrieb.LINK_FIELD_ID}"
          value="${this.backendData.dmsLink}"
          label="DMS"
          @change="${e => this._updateValue(e)}"
          ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
          maxLength="400"
        ></isceco-text-input>
    ` : html``
  }

  _renderExistingBetriebe() {
    if (this.otherBetriebeWithSameUid.length <= 0) {
      return html``
    }
    if (this.otherBetriebeWithSameUid.length === 1) {
      return this._renderExistingBetriebInfo(this.otherBetriebeWithSameUid[0])
    }
    return html`
      <div>
        ${this.otherBetriebeWithSameUid.map(b => html`${this._renderExistingBetriebInfo(b)}`)}
      </div>
    `
  }

  _getLink(gesuchId, betriebId) {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.betrieb.hash}/${gesuchId}/${betriebId}`
  }

  _renderExistingBetriebInfo(betrieb) {
    return html`
      <div>
        <i class="exclamation triangle icon"></i>
        <a href="${this._getLink(betrieb.gesuchId, betrieb.id)}" target="_blank">
          ${this.i18n.translate('geschaeftsvorfall.betrieb.betrieb.already.exists', {
            firmenbezeichnung: betrieb.firmenbezeichnung,
            uid: betrieb.unternRegisterId,
            kanton: betrieb.codeKanton
          })} <i class="icon external alternate"></i></a>
      </div>
    `
  }

  _renderDialogs() {
    return html`
      <vzavg-betrieb-diff-dialog
        id="betrieb-diff-dialog"
        .i18n="${this.i18n}"
      ></vzavg-betrieb-diff-dialog>
    `
  }

  _renderMandatoryInfo() {
    return html`
      <isceco-radio-group
        id="inAbklaerung"
        name="inAbklaerung"
        value="${this.backendData.inAbklaerung}"
        label="${this.i18n.translate('geschaeftsvorfall.betrieb.bewilligungspflicht.in.clarification')}"
        .items="${Options.yesNo}"
        @change="${e => this._updateValueAndTelefonPatternAndReload(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        .disabled="${(this.backendData.codeKanton === 'SECO')}"
        .helpText="${this.backendData.codeKanton === 'SECO' ? this.i18n.translate('geschaeftsvorfall.betrieb.in.clarification.disable.msg') : ''}"
      ></isceco-radio-group>
      ${this._isNotInAbklaerung() ? html`
        <div class="flex">
          <isceco-dropdown
            id="${Betrieb.BEWILLIGGUNGSPFLICHT_FIELD_ID}"
            name="${Betrieb.BEWILLIGGUNGSPFLICHT_FIELD_ID}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.bewilligungspflicht')} *"
            value="${this.backendData.bewilligungspflicht}"
            .items="${this._bewilligungspflichtOptions()}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            @change="${e => this._updateValueAndReload(e)}"
            required
          ></isceco-dropdown>
        </div>
      ` : html``}
    `
  }

  _renderUIDButton() {
    return this._isLandSchweizOrLichtenstein() ?
      html`
        <vzavg-uid-search-button
          @change="${e => this._setBetriebSearchData(e.detail)}"
          .disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          value="${this.backendData.unternRegisterId}"
          name="unternRegisterId"
          id="${Betrieb.BETRIEB_UID_ADD_BUTTON}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ?required="${this._isUidFieldRequired()}"
          .label="${this.i18n.translate('geschaeftsvorfall.betrieb.untern.register.id') + this._getStarIfUidIsRequired()}"
          kanton="${this.backendData.codeKanton}"
        ></vzavg-uid-search-button>
      ` : html``
  }

  _bewilligungspflichtOptions() {
    return [
      {value: 'yes', name: this.i18n.translate('geschaeftsvorfall.betrieb.bewilligungspflicht.yes')},
      {value: 'no', name: this.i18n.translate('geschaeftsvorfall.betrieb.bewilligungspflicht.no')}
    ]
  }

  _handleChangeLand(e) {
    if (e.detail.value !== Betrieb.SCHWEIZ) {
      this.backendData.unternRegisterId = null
    }
    this._updateValueAndTelefonPatternAndReload(e)
  }

  _updateValueAndTelefonPatternAndReload(e) {
    this._updateValue(e)
    this._configureTelefonField()
    this.reload()
  }

  _handleClickNext() {
    const link = geschaeftsVorfallNavigationLinksHelper.next()
    navigate({to: link.hash, id: this.gesuchId})
  }

  _openDiffDialog(existingBetrieb, newBetriebValues, changedFields) {
    const dialog = document.querySelector('#betrieb-diff-dialog')
    dialog.existingBetrieb = existingBetrieb
    dialog.newBetriebValues = newBetriebValues
    dialog.changedFields = changedFields

    return new Promise(resolve => {
      dialog.callback = diffResult => {
        this._handleDiffResultCallback(diffResult).then(saved => {
          resolve(saved)
        })
      }
      document.querySelector('#betrieb-diff-dialog').shadowRoot
        .querySelector('#isceco-diff-dialog')
        .removeAttribute('hidden')
    })
  }

  _setBetriebSearchData = row => {
    this.backendData.unternRegisterId = row.uid
    this.backendData.firmenbezeichnung = row.name
    this.backendData.strasse = `${row.street ?? ''} ${row.houseNumber ?? ''}`.trim()
    this.backendData.plz = row.swissZipCode
    this.backendData.ort = row.town
    this.reload()
  }

  _handleKantonCheckList() {
    this.kantonCheckListChecks = document.getElementById('kantonCheckListChecks').getValue()
    this.save()
  }

  _handleSecoCheckList() {
    this.secoCheckListChecks = document.getElementById('secoCheckListChecks').getValue()
    this.save()
  }

  save() {
    const formElement = document.getElementById(Betrieb.BETRIEB_FORM_ID)

    if (!formElement.validate()) {
      const telefonInput = document.getElementById(Betrieb.TELEFON_FIELD_ID);
      const telefonInputCanBeEmpty = !this._isTelOrEmailRequired() && telefonInput.getValue() === Betrieb.TELEFON_DEFAULT
      if (telefonInputCanBeEmpty) {
        telefonInput.value = ''
        this.backendData.telefon = ''
      }

      if (!formElement.validate()) {
        this.reload()
        return Promise.resolve(false)
      }
    }

    const formData = formElement.getValues()
    formData.unternRegisterId = this.backendData.unternRegisterId
    formData.inAbklaerung = this.backendData.inAbklaerung
    formData.kantonCheckListChecks = this.kantonCheckListChecks
    formData.secoCheckListChecks = this.secoCheckListChecks
    if (this._isLandSchweizOrLichtenstein()) {
      formData.plz = formData.plzOrt?.plz
      formData.ort = formData.plzOrt?.ortBez
    }
    formData.isKantonChecklistOverridden = this.backendData.isKantonChecklistOverridden
    formData.isSecoChecklistOverridden = this.backendData.isSecoChecklistOverridden

    if (formData.unternRegisterId) {
      return this._handleSaveWithUidAndKanton(formData)
    } else {
      return this._createOrUpdate(formData)
    }
  }

  _handleSaveWithUidAndKanton(formDatas) {
    if (this._hasGeneratedUid(formDatas)) {
      return this._createOrUpdate(formDatas)
    }

    return this.searchService.search(`/local/${formDatas.unternRegisterId}/${formDatas.kanton}`)
      .then(result => {
        if (result.id) {
          const existingBetrieb = this._convertToBetriebForDiff(result)
          const newBetriebValues = this._convertToBetriebForDiff(formDatas)
          const [hasChangedFields, changedFields] = DataHelper.compareData(existingBetrieb, newBetriebValues)

          if (!hasChangedFields) {
            return this._createOrUpdate(formDatas)
          } else {
            return this._openDiffDialog(existingBetrieb, newBetriebValues, changedFields)
          }

        } else {
          return this._createOrUpdate(formDatas)
        }
      })
  }

  _hasGeneratedUid(formDatas) {
    return formDatas.unternRegisterId?.length === 36
  }

  _convertToBetriebForDiff(values) {
    return {
      id: values.id ? values.id : null,
      uid: values.unternRegisterId ? values.unternRegisterId : null,
      firmenbezeichnung: values.firmenbezeichnung ? values.firmenbezeichnung : null,
      adresszusatz: values.adresszusatz ? values.adresszusatz : null,
      strasse: values.strasse ? values.strasse : null,
      plz: values.plz ? values.plz : null,
      ort: values.ort ? values.ort : null,
      land: values.land ? values.land : null
    }
  }

  _handleDiffResultCallback = diffResult => {
    if (diffResult.confirmed) {
      if (diffResult.overwrite) {
        // overwrite existing betrieb with given values
        const formElement = document.getElementById(Betrieb.BETRIEB_FORM_ID)
        const values = formElement.getValues()
        values.kantonCheckListChecks = this.kantonCheckListChecks
        values.secoCheckListChecks = this.secoCheckListChecks

        values.unternRegisterId = this.backendData.unternRegisterId
        if (this._isLandSchweizOrLichtenstein()) {
          values.plz = values.plzOrt?.plz
          values.ort = values.plzOrt?.ortBez
        }

        return this._createOrUpdate(values)
      } else {
        // replace values with existing betrieb
        const formElement = document.getElementById(Betrieb.BETRIEB_FORM_ID)
        const formDatas = formElement.getValues()
        const betrieb = diffResult.betrieb

        formDatas.unternRegisterId = this.backendData.unternRegisterId
        formDatas.id = betrieb.id
        formDatas.unternRegisterId = betrieb.uid
        formDatas.firmenbezeichnung = betrieb.firmenbezeichnung
        formDatas.adresszusatz = betrieb.adresszusatz
        formDatas.strasse = betrieb.strasse
        formDatas.plz = betrieb.plz
        formDatas.ort = betrieb.ort
        formDatas.land = betrieb.land
        formDatas.kantonCheckListChecks = this.kantonCheckListChecks
        formDatas.secoCheckListChecks = this.secoCheckListChecks
        formDatas.isKantonChecklistOverridden = this.backendData.isKantonChecklistOverridden
        formDatas.isSecoChecklistOverridden = this.backendData.isSecoChecklistOverridden
        return this._createOrUpdate(formDatas)
      }
    }
    return Promise.resolve(false)
  }

  _createOrUpdate(formDatas) {
    formDatas.codeKanton = this.backendData.codeKanton

    this.backendData = Object.assign(this.backendData, formDatas);
    this.backendData.kantonCheckList = formDatas.kantonCheckList
    this.backendData.secoCheckList = formDatas.secoCheckList
    this.backendData.kantonCheckListChecks = formDatas.kantonCheckListChecks
    this.backendData.secoCheckListChecks = formDatas.secoCheckListChecks
    this.backendData.bewilligungspflicht = this.backendData.inAbklaerung === 'yes' ? undefined : this.backendData.bewilligungspflicht

    if (this.backendData.id) {
      formDatas.id = this.backendData.id
      return this.service.update(this.backendData, +this.gesuchId + '/betrieb')
        .then(gesuchId => {
          this.gesuchId = gesuchId
          showAlert(
            this.i18n.translate('betrieb.update.success.title'),
            this.i18n.translate('betrieb.update.success.message'),
            'success'
          );
          this._updateBetrieb()
          send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
          return true
        }).catch(_ => false)
    } else {
      return this.service.create(this.backendData, `${this.gesuchId}/betrieb`)
        .then(gesuchId => {
          this.gesuchId = gesuchId
          showAlert(
            this.i18n.translate('betrieb.create.success.title'),
            this.i18n.translate('betrieb.create.success.message'),
            'success'
          )
          navigate({to: geschaeftsVorfallNavigationLinks.betrieb.hash, id: gesuchId})
          return true
        }).catch(_ => false)
    }
  }

  _updateKantonCode(event) {
    this._updateValueWithDifferentKey('codeKanton', event)
    if (event.detail.value === 'SECO') {
      this.backendData.inAbklaerung = 'yes'
    }
    this.reload()
  }

  _updateValueWithDifferentKey(key, event) {
    if (this.backendData[key] !== event.detail.value) {
      window.hasChanges = true
      this.backendData[key] = event.detail.value
    }
  }

  _updateValue(event) {
    const key = event.target.id
    if (this.backendData[key] !== event.detail.value) {
      window.hasChanges = true
      this.backendData[key] = event.detail.value
    }

    const telefonElement = document.getElementById(Betrieb.TELEFON_FIELD_ID)
    if (!this._isTelOrEmailRequired() &&
      this.backendData.telefon !== undefined &&
      this.backendData.telefon.length <= Betrieb.TELEFON_DEFAULT.length) {
      this.backendData.telefon = ''
      this._configureTelefonPlaceholder()
      telefonElement.classList.remove('error')
    } else if (this.backendData.telefon === undefined || this.backendData.telefon.length < 1) {
      this._configureTelefonPlaceholder()
      telefonElement.classList.remove('error')
    }
  }

  _updatePlzOrtValue(event) {
    this.backendData.plz = event.detail.plz
    this.backendData.ort = event.detail.ortBez
    window.hasChanges = true
  }

  _updateValueAndReload(event) {
    this._updateValue(event)
    this.reload()
  }

  _updateZAVerbot(event) {
    this.isShowingNotizErfassen = event.detail.value === 'yes';
    this._updateValueAndReload(event)
  }

  _updateBetrieb() {
    this.service.read(this.gesuchId, 'betrieb')
      .then(gesuchBetriebResponse => {
        // check if http response status is NoContent = 204 , then init for new entry
        if (!isNoDataFound(gesuchBetriebResponse)) {
          window.betrieb$.setValue(gesuchBetriebResponse)
          sessionStorage.setItem('betriebId', gesuchBetriebResponse.id)
        }
      })
  }

  _createDefaultBackendData() {
    return {
      kantonCheckList: [],
      secoCheckList: [],
      inAbklaerung: this._defaultInAbklaerung,
      bewilligungspflicht: this._defaultBewilligungspflicht,
      berufsverband: 'no',
      korrespondenzSprache: this._getLanguage(),
      codeKanton: this._defaultKanton,
      zaVerbot: 'no',
      portalUser: 'no',
      telefon: '',
      land: Betrieb.SCHWEIZ,
      rgEmpfang: 'paper',
      isKantonChecklistOverridden: false,
      isSecoChecklistOverridden: false
    }
  }

  _getLanguage() {
    const languageMap = {
      'NE': 'fr',
      'GE': 'fr',
      'VD': 'fr',
      'JU': 'fr',
      'FR': 'fr',
      'TI': 'it'
    };
    const kanton = sessionStorage.getItem('userRechtCodeKanton');
    return languageMap[kanton] || 'de';
  }

  _openNewNotiz() {
    this.save().then(formValidated => {
      if(formValidated) {
        const firmaName = this.backendData.firmenbezeichnung
        const newNotizUrl = this._buildNotizUrl(firmaName)
        window.open(newNotizUrl, "_blank");
      }
    })
  }

  _buildNotizUrl(firmaName) {
    const zaVerbotTitle = this.i18n.translate('geschaeftsvorfall.betrieb.za.notiz.title')
    const title = `${zaVerbotTitle} ${firmaName}`
    const to = `#${geschaeftsVorfallNavigationLinks.notizEdit.hash}`
    const id = this.gesuchId
    const params = [
      ['kategorie', 'Zusammenarbeitsverbot'],
      ['title', encodeURIComponent(title)]
    ]
    return constructURL({to, id, params})
  }

  get kantonIsRequired() {
    return this._isNotInAbklaerung()
  }

  get responsibilityLabel() {
    return this.i18n.translate('geschaeftsvorfall.betrieb.responsibility') + (this.kantonIsRequired ? ' *' : '')
  }

  get plzLabel() {
    return this.i18n.translate('geschaeftsvorfall.betrieb.plz') + (this._isBewilligungsPflichtig() ? ' *' : '')
  }

  get ortLabel() {
    return this.i18n.translate('geschaeftsvorfall.betrieb.ort') + (this._isBewilligungsPflichtig() ? ' *' : '')
  }

  get plzOrtAuslandLabel() {
    return this.i18n.translate('geschaeftsvorfall.betrieb.plzOrtAusland') + (this._isBewilligungsPflichtig() ? ' *' : '')
  }

  get _defaultKanton() {
    if (AuthorizationFilter.hasSecoRole()) {
      return 'SECO'
    } else if (AuthorizationFilter.hasKantonRole()) {
      return sessionStorage.getItem('userRechtCodeKanton')
    } else {
      return ''
    }
  }

  get _defaultInAbklaerung() {
    if (AuthorizationFilter.hasSecoRole()) {
      return 'yes'
    }
    return 'no'
  }

  get _defaultBewilligungspflicht() {
    if (AuthorizationFilter.hasSecoRole()) {
      return 'no'
    }
    return 'yes'
  }

  _getStarIfUidIsRequired() {
    return this._isUidFieldRequired() ? ' *' : ''
  }

  _isAuslandUndSeco() {
    return this.backendData.land !== Betrieb.SCHWEIZ && this.backendData.land !== Betrieb.LIECHTENSTEIN && this.backendData.codeKanton === 'SECO'
  }

  _isLiechtensteinUndStGallen() {
    return this.backendData.land === Betrieb.LIECHTENSTEIN && this.backendData.codeKanton === 'SG'
  }

  _isSchweizAlleKantoneNichtInAbklaehrung() {
    return this._isLandSchweiz() && !isEmpty(this.backendData.codeKanton) && this._isNotInAbklaerung()
  }

  _isBewilligungsPflichtig() {
    return this.backendData.bewilligungspflicht === 'yes' && this._isNotInAbklaerung()
  }

  _isLandSchweiz() {
    return this.backendData.land === Betrieb.SCHWEIZ
  }

  _isLandLiechtenstein() {
    return this.backendData.land === Betrieb.LIECHTENSTEIN
  }

  _isLandSchweizOrLichtenstein() {
    return this._isLandSchweiz() || this._isLandLiechtenstein()
  }

  _isUidFieldRequired() {
    return this.backendData.bewilligungspflicht === 'yes' && this._isNotInAbklaerung() && this.backendData.gemeinnuetzigkeit === 'no' && this.backendData.berufsverband === 'no'
  }

  _isNotInAbklaerung() {
    return this.backendData.inAbklaerung === 'no'
  }

  _isAdressFieldRequired() {
    return this.backendData.bewilligungspflicht === 'yes' && this._isLandSchweizOrLichtenstein() && this._isNotInAbklaerung()
  }

  _isTelOrEmailRequired() {
    return this.backendData.bewilligungspflicht === 'yes' && this._isNotInAbklaerung()
  }

  _handleChecklistOverride() {
    if (AuthorizationFilter.hasKantonRole()) {
      this.backendData.isKantonChecklistOverridden = true
    } else {
      this.backendData.isSecoChecklistOverridden = true
    }
    this.backendData.isChecklistOverridden = true
    this.save()
  }

  _configureTelefonField() {
    if (this._isLandSchweizOrLichtenstein() && this._isNotInAbklaerung()) {
      this.telefonPattern = VzavgPattern.swissTelefonPattern
      this.telefonPatternErrorMsg = this.i18n.translate('geschaeftsvorfall.betrieb.swiss.telefon.pattern.error')
    } else {
      this.telefonPattern = VzavgPattern.simpleTelefonPattern
      this.telefonPatternErrorMsg = this.i18n.translate('geschaeftsvorfall.betrieb.telefon.pattern.error')
    }

    this._configureTelefonPlaceholder()
  }

  _configureTelefonPlaceholder() {
    if (this._isLandSchweiz()) {
      this.telefonPlaceholder = Betrieb.TELEFON_DEFAULT
    }  else if (this._isLandLiechtenstein()){
      this.telefonPlaceholder = Betrieb.TELEFON_DEFAULT_LI
    } else {
      this.telefonPlaceholder =''
    }
  }
}
customElements.define('vzavg-frontend-geschaeftsvorfall-betrieb', Betrieb)
