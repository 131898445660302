import {html, css} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'

export default class Auswertung extends WebComponent {

  get translationFile() {
    return './views/Auswertung/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  get css() {
    return css`

      #auswertung-selection {
        display: flex;
        flex-direction: row;
        gap: 4em;
      }

      #auswertung-content {
        margin-top: 4em;
        display: flex;
      }

      .auswertung-form {
        display: flex;
        flex-direction: column;
        gap: 2em;
      }


      .card {
        display: block;
        width: 25%;
        text-align: center;
        border-width: 4px;
        border-radius: 20px;
        border-style: outset;
        background: linear-gradient(var(--isceco-color-background), var(--isceco-color-white-500));
      }

      .card:hover {
        cursor: pointer;
        background: linear-gradient(var(--isceco-color-grey-100), var(--isceco-color-white-500));
      }

      .card.active {
        cursor: pointer;
        background: var(--isceco-color-grey-100);
      }

      .download-dialog-input {
        width: calc(100% - 40px);
        display: inline-block;
      }
    `
  }

  getTemplate() {
    return html`
      <div id="auswertung-selection">
        <isceco-card class="card ${this._getActive('Auswertung_1')}" @click="${() => navigate({to: 'Auswertung_1'})}">
          <h3>${this.i18n.translate('auswertung.auswertung1.title')}</h3>
          <p>${this.i18n.translate('auswertung.auswertung1.description')}</p>
        </isceco-card>
        <isceco-card class="card ${this._getActive('Auswertung_2')}" @click="${() => navigate({to: 'Auswertung_2'})}">
          <h3>${this.i18n.translate('auswertung.auswertung2.title')}</h3>
          <p>${this.i18n.translate('auswertung.auswertung2.description')}</p>
        </isceco-card>
        <isceco-card class="card ${this._getActive('Auswertung_3')}" @click="${() => navigate({to: 'Auswertung_3'})}">
          <h3>${this.i18n.translate('auswertung.auswertung3.title')}</h3>
          <p>${this.i18n.translate('auswertung.auswertung3.description')}</p>
        </isceco-card>
        <isceco-card class="card ${this._getActive('Auswertung_4')}" @click="${() => navigate({to: 'Auswertung_4'})}">
          <h3>${this.i18n.translate('auswertung.auswertung4.title')}</h3>
          <p>${this.i18n.translate('auswertung.auswertung4.description')}</p>
        </isceco-card>
      </div>
      <div id="auswertung-content">
        ${this.getContent()}
      </div>
    `
  }

  getContent() {
    switch (getNavigationHash()) {
      case 'Auswertung_1':
        return this._getAuswertung1Content()
      case 'Auswertung_2':
        return this._getAuswertung2Content()
      case 'Auswertung_3':
        return this._getAuswertung3Content()
      case 'Auswertung_4':
        return this._getAuswertung4Content()
      default:
        break;
    }
    // default
    return html``
  }

  _getAuswertung1Content() {
    return html`
      <div class="auswertung-form">
        <isceco-number-input
          id="auswertung1-year"
          name="year"
          .value="${new Date().getFullYear()}"
          maximum-fraction-digits="${0}"
          .label="${this.i18n.translate('auswertung.berichtsjahr')}"
          inline="true"
          required
        >
        </isceco-number-input>
        ${this._getDownloadButton(filename => this._downloadAuswertung1(filename))}
      </div>
    `
  }

  _getAuswertung2Content() {
    return html`
      <div class="auswertung-form">
        <isceco-dropdown
          id="auswertung2-period"
          name="period"
          value="3"
          .items="${[{name: this.i18n.translate('auswertung.zeitraum.3jahre'), value: 3}, {name: this.i18n.translate('auswertung.zeitraum.5jahre'), value: 5}]}"
          .label="${this.i18n.translate('auswertung.zeitraum')}"
          inline="true"
          required
        >
        </isceco-dropdown>
        ${this._getDownloadButton(filename => this._downloadAuswertung2(filename))}
      </div>
    `
  }

  _getAuswertung3Content() {
    return html`
      <div class="auswertung-form">
        <isceco-dropdown
          id="auswertung3-period"
          name="period"
          value="3"
          .items="${[{name: this.i18n.translate('auswertung.zeitraum.3jahre'), value: 3}, {name: this.i18n.translate('auswertung.zeitraum.5jahre'), value: 5}]}"
          .label="${this.i18n.translate('auswertung.zeitraum')}"
          inline="true"
          required
        >
        </isceco-dropdown>
        ${this._getDownloadButton(filename => this._downloadAuswertung3(filename))}
      </div>
    `
  }

  _getAuswertung4Content() {
    return html`
      <div class="auswertung-form">
        <isceco-date-input
          id="auswertung4-date"
          name="date"
          type="date"
          .value="${new Date().toISOString().slice(0, 10)}"
          .label="${this.i18n.translate('auswertung.standper')}"
          inline="true"
          required
        ></isceco-date-input>
        ${this._getDownloadButton(filename => this._downloadAuswertung4(filename))}
      </div>
    `
  }

  _getDownloadButton(downloadFunction) {
    return html`
      <isceco-button
          class="download-button" icon="cloud download alternate" .text="${this.i18n.translate('auswertung.download')}"
          @click="${() => this._openDownloadDialog()}"
        ></isceco-button>
        <isceco-dialog id="download-dialog"
                       hidden
                       header="${this.i18n.translate('auswertung.download.header')}"
                       description="${this.i18n.translate('auswertung.download.description')}"
                       confirm-button="${this.i18n.translate('auswertung.download.confirm')}"
                       Cancel-button="${this.i18n.translate('auswertung.download.cancel')}"
                       @submit="${e => this._downloadAuswertung(e, downloadFunction)}"
        >
          <isceco-text-input
            id="download-filename"
            class="download-dialog-input"
            maxlength="30"
          ></isceco-text-input>
          .xlsx
        </isceco-dialog>
    `
  }

  _getActive(navigationHash) {
    return getNavigationHash() === navigationHash ? 'active' : ''
  }

  _openDownloadDialog() {
    const dialog = document.querySelector(`#download-dialog`)
    dialog.querySelector(`#download-filename`).value = this._getFilenameSuggestion()
    dialog.removeAttribute('hidden')
  }

  _getFilenameSuggestion() {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
    const suffix = getNavigationHash()
    return `${formattedDate}_${suffix}`
  }

  _downloadAuswertung(e, downloadFunction) {
    if (e.detail.confirmed) {
      const filename = toValidFilename(e.target.querySelector(`#download-filename`).getValue())
      downloadFunction(filename)
    }
    e.target.setAttribute('hidden', '')
  }

  _downloadAuswertung1(filename) {
    // const year = document.getElementById('auswertung1-year').getValue()
    // implement download
  }

  _downloadAuswertung2(filename) {
    // const period = document.getElementById('auswertung2-period').getValue()
    // implement download
  }

  _downloadAuswertung3(filename) {
    // const period = document.getElementById('auswertung3-period').getValue()
    // implement download
  }

  _downloadAuswertung4(filename) {
    // const date = document.getElementById('auswertung4-date').getValue()
    // implement download
  }

}
customElements.define('vzavg-frontend-auswertung', Auswertung)
